import Select from 'react-select'
import styled from 'styled-components'

import {
  Button,
  Icon,
  TextInput,
  Well,
  useDialog,
} from '@sportsyou/react-dom-ui'
import {
  Campaign,
  CampaignCriteria,
  Location,
  QueryCampaignReachArgs,
  queryCampaignReach,
  queryMetroAreas,
  querySports,
} from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { useCallback, useEffect, useState } from 'react'
import { useFetchApi } from '@sportsyou/react-hooks'
import LocationPickerItem from '../../SponsoredPosts/components/LocationPickerItem'

interface AudienceFormProps {
  audience: CampaignCriteria
  campaignId?: string
  hasChanges?: boolean
  isEditable?: boolean
  isNew?: boolean
  setCampaignProp: (prop: keyof Campaign, value: unknown) => void
  sponsorId?: string
}

interface Option {
  label: string
  value: string
}

const audienceUserTypesOptions: Option[] = [
  { label: 'Admins', value: 'admin' },
  { label: 'Non-Admins', value: 'non-admin' },
]

const audienceGendersOptions: Option[] = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
]

const audienceRolesOptions: Option[] = [
  { label: 'Coaches', value: 'coach' },
  { label: 'Players', value: 'player' },
  { label: 'Family', value: 'parent' },
  { label: 'Others', value: 'other' },
]

export default function AudienceForm(props: AudienceFormProps) {
  const { campaignId, sponsorId, setCampaignProp } = props

  const { sendBanner } = useDialog()

  const [audienceGenders, setAudienceGenders] = useState<Option[]>([])
  const [audienceMetroAreas, setAudienceMetroAreas] = useState<any[]>([])
  const [audienceRoles, setAudienceRoles] = useState<Option[]>([])
  const [audienceSports, setAudienceSports] = useState<Option[]>()
  const [audienceUserTypes, setAudienceUserTypes] = useState<Option[]>([])
  const [initialLoad, setInitialLoad] = useState(true)
  const [isLoadingReach, setIsLoadingReach] = useState(false)
  const [locations, setLocations] = useState<Location[]>([])
  const [maxAge, setMaxAge] = useState<number>()
  const [metroAreaOptions, setMetroAreaOptions] = useState<any[]>([])
  const [metroAreas, setMetroAreas] = useState<any[]>([])
  const [minAge, setMinAge] = useState<number>()
  const [reach, setReach] = useState<number>()
  const [sports, setSports] = useState<any[]>([])
  const [sportsOptions, setSportsOptions] = useState<Option[]>()

  const { fetch: getCampaignReach } = useFetchApi(queryCampaignReach)
  const { fetch: getMetroAreas } = useFetchApi(queryMetroAreas)
  const { fetch: getSports } = useFetchApi(querySports)

  const buildAudience = useCallback(() => {
    const filterBlankLocations = (locations: Location[]) => {
      return locations.filter((location) => {
        return (
          !!location.city?.trim().length ||
          !!location.stateProvince?.trim().length ||
          !!location.postalCode?.trim().length ||
          !!location.country?.trim().length
        )
      })
    }

    return {
      genders: audienceGenders?.map((a) => a.value) ?? [],
      locations: filterBlankLocations((locations as Location[]) ?? []),
      maxAge: parseInt((maxAge ?? 200).toString(), 10),
      minAge: parseInt((minAge ?? 13).toString(), 10),
      sportIds: audienceSports?.map((a) => parseInt(a.value, 10)) ?? [],
      teamRoles: audienceRoles?.map((a) => a.value) ?? [],
      userTypes: audienceUserTypes?.map((a) => a.value) ?? [],
      metroAreas: audienceMetroAreas?.map((a) => a.value) ?? [],
    }
  }, [
    audienceGenders,
    locations,
    maxAge,
    minAge,
    audienceSports,
    audienceRoles,
    audienceUserTypes,
    audienceMetroAreas,
  ])

  const handleOnClickRefreshReach = useCallback(async () => {
    setIsLoadingReach(true)
    const params: QueryCampaignReachArgs = {
      campaignId,
      criteria: buildAudience(),
      sponsorId,
    }
    const { data, ok } = await getCampaignReach(params)
    if (ok) {
      setReach(data?.activeUserCount ?? undefined)
    } else {
      sendBanner({
        autoDismiss: true,
        dismissTime: 2000,
        message: 'Could not refresh reach at this time.',
        status: 'alert',
      })
    }
    setIsLoadingReach(false)
  }, [buildAudience, campaignId, getCampaignReach, sendBanner, sponsorId])

  const onAddAnotherLocation = useCallback(() => {
    setLocations((_locations) => [..._locations, {} as Location])
  }, [])

  const onChangeLocationPickerItem = useCallback(
    (location: Location, index: number) => {
      setLocations((_locations) => {
        const newLocations = [..._locations]
        newLocations[index] = location
        return newLocations
      })
    },
    []
  )

  const onRemoveLocationPickerItem = useCallback((index: number) => {
    setLocations((_locations) => {
      const newLocations = [..._locations]
      newLocations.splice(index, 1)
      return newLocations
    })
  }, [])

  const buildSportsOptions = useCallback((_sports?: any[]) => {
    return (
      _sports?.map((sport) => ({
        label: sport.name,
        value: sport.id,
      })) ?? []
    )
  }, [])

  const buildMetroAreaOptions = useCallback((_metroAreas?: any[]) => {
    return (
      _metroAreas?.map((area) => ({
        label: area,
        value: area,
      })) ?? []
    )
  }, [])

  const fetchData = useCallback(async () => {
    if (props.isNew) {
      setSports((await getSports()).data ?? [])
      setMetroAreas((await getMetroAreas()).data ?? [])
    } else {
      const hydrateAudience = (setter: any, values: any[], options: any[]) => {
        setter(
          values.map((value) =>
            options.find((option) => option.value === value)
          )
        )
      }

      setMaxAge((props.audience?.maxAge as number) ?? 200)
      setMinAge((props.audience?.minAge as number) ?? 13)
      setLocations((props.audience?.locations as Location[]) ?? [])

      hydrateAudience(
        setAudienceGenders,
        props.audience?.genders ?? [],
        audienceGendersOptions
      )
      hydrateAudience(
        setAudienceRoles,
        props.audience?.teamRoles ?? [],
        audienceRolesOptions
      )
      hydrateAudience(
        setAudienceUserTypes,
        props.audience?.userTypes ?? [],
        audienceUserTypesOptions
      )

      const _sports = (await getSports()).data ?? []
      setSports(_sports)

      hydrateAudience(
        setAudienceSports,
        props.audience?.sportIds ?? [],
        buildSportsOptions(_sports)
      )

      const _metroAreas = (await getMetroAreas()).data ?? []
      setMetroAreas(_metroAreas)
      hydrateAudience(
        setAudienceMetroAreas,
        props.audience?.metroAreas ?? [],
        buildMetroAreaOptions(_metroAreas)
      )
    }
  }, [
    buildMetroAreaOptions,
    buildSportsOptions,
    getMetroAreas,
    getSports,
    props.audience?.genders,
    props.audience?.locations,
    props.audience?.maxAge,
    props.audience?.metroAreas,
    props.audience?.minAge,
    props.audience?.sportIds,
    props.audience?.teamRoles,
    props.audience?.userTypes,
    props.isNew,
  ])

  useEffect(() => {
    const _sports = buildSportsOptions(sports)
    setSportsOptions(_sports)

    const _metroAreas = buildMetroAreaOptions(metroAreas)
    setMetroAreaOptions(_metroAreas)
  }, [buildMetroAreaOptions, buildSportsOptions, metroAreas, sports])

  useEffect(() => {
    if (initialLoad) {
      fetchData()
      setInitialLoad(false)
    }
  }, [fetchData, initialLoad])

  useEffect(() => {
    const selections = buildAudience()
    setCampaignProp('selections', selections)
  }, [buildAudience, setCampaignProp])

  return (
    <Well label='Audience'>
      <TextInputContainer>
        <span className='multi-select__label'>User Types</span>
        <Select
          classNamePrefix='multi-select'
          isMulti
          onChange={(value: any) => setAudienceUserTypes(value)}
          options={audienceUserTypesOptions}
          placeholder='All User Types'
          value={audienceUserTypes}
          isDisabled={!props.isEditable}
        />
      </TextInputContainer>
      <TextInputContainer>
        <span className='multi-select__label'>Genders</span>
        <Select
          classNamePrefix='multi-select'
          isMulti
          onChange={(value: any) => setAudienceGenders(value)}
          options={audienceGendersOptions}
          placeholder='All Genders'
          value={audienceGenders}
          isDisabled={!props.isEditable}
        />
      </TextInputContainer>
      <TextInputContainer>
        <span className='multi-select__label'>Roles</span>
        <Select
          classNamePrefix='multi-select'
          isMulti
          onChange={(value: any) => setAudienceRoles(value)}
          options={audienceRolesOptions}
          placeholder='All Roles'
          value={audienceRoles}
          isDisabled={!props.isEditable}
        />
      </TextInputContainer>
      <TextInputContainer>
        <span className='multi-select__label'>Sports</span>
        <Select
          classNamePrefix='multi-select'
          isMulti
          onChange={(value: any) => setAudienceSports(value)}
          options={sportsOptions}
          placeholder='All Sports'
          value={audienceSports}
          isDisabled={!props.isEditable}
        />
      </TextInputContainer>
      <TextInputContainer>
        <span className='multi-select__label'>Metro Areas</span>
        <Select
          classNamePrefix='multi-select'
          isMulti
          onChange={(value: any) => setAudienceMetroAreas(value)}
          options={metroAreaOptions}
          placeholder='All Metro Areas'
          value={audienceMetroAreas}
          isDisabled={!props.isEditable}
        />
      </TextInputContainer>
      <TextInputContainer>
        <InputContainer>
          <InputContainerLabel>Locations</InputContainerLabel>

          <LocationList>
            {!locations?.length && (
              <LocationListPlaceholder>Everywhere</LocationListPlaceholder>
            )}
            {locations?.map((location, index) => (
              <LocationPickerItem
                index={index}
                key={index}
                location={location}
                onChange={onChangeLocationPickerItem}
                onRemove={onRemoveLocationPickerItem}
              />
            ))}
          </LocationList>
          {props.isEditable && (
            <AddLocationButton onClick={onAddAnotherLocation} variant='primary'>
              <Icon fill={Colors.WHITE} name='Plus' height={10} /> Set a
              specific Location
            </AddLocationButton>
          )}
        </InputContainer>
      </TextInputContainer>
      <TextInputContainer>
        <AgeRangeContainer>
          <TextInputStyled
            containerStyle={{ width: '100px' }}
            label='Min Age'
            maxLength={2}
            onChange={(e) =>
              setMinAge(
                e.target.value ? parseInt(e.target.value, 10) : undefined
              )
            }
            placeholder='13'
            type='text'
            value={minAge?.toString()}
            disabled={!props.isEditable}
          />
          –
          <TextInputStyled
            containerStyle={{ width: '100px' }}
            label='Max Age'
            maxLength={2}
            onChange={(e) =>
              setMaxAge(
                e.target.value ? parseInt(e.target.value, 10) : undefined
              )
            }
            placeholder='99'
            type='text'
            value={(maxAge ?? '').toString()}
            disabled={!props.isEditable}
          />
        </AgeRangeContainer>
      </TextInputContainer>
      {props.isEditable && (
        <TextInputContainer>
          <hr />
          <h3>Estimated Reach: {reach !== undefined ? `~${reach}` : '--'}</h3>
          <Button
            disabled={isLoadingReach}
            loading={isLoadingReach}
            loadingText='Checking...'
            onClick={handleOnClickRefreshReach}
          >
            Check Audience Reach
          </Button>
        </TextInputContainer>
      )}
    </Well>
  )
}

const TextInputContainer = styled.div`
  margin-bottom: 1rem;
`

const TextInputStyled = styled(TextInput)`
  width: 650px;
`

const AgeRangeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
`

const InputContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
`

const InputContainerLabel = styled.div`
  align-items: center;
  color: ${Colors.PUNCH};
  display: flex;
  font-size: 13px;
  font-weight: 700;
  justify-content: space-between;
  text-transform: uppercase;
`

const LocationList = styled.div``

const LocationListPlaceholder = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
`

const AddLocationButton = styled(Button)`
  height: 25px;
  margin-bottom: 5px;
  min-height: 25px;
`
