import { FC } from 'react'
import styled, { css } from 'styled-components'

import { BrandSportsyou, BrandSy } from '@sportsyou/react-icons'
import { Colors } from '@sportsyou/core'
import { useMediaQuery } from '@sportsyou/react-hooks'

export type BrandProduct = {
  name: string
  onClick?: (event: React.MouseEvent) => void
  title?: string
}

export interface NavbarBrandProps {
  color?: string
  href?: string
  mobileBreakpoint?: number
  onClick?: (event: React.MouseEvent) => void
  product?: BrandProduct
}

export const NavbarBrand: FC<NavbarBrandProps> = ({
  color,
  href,
  mobileBreakpoint,
  onClick,
  product,
}: NavbarBrandProps) => {
  const isSmallScreen = useMediaQuery(`(min-width: ${mobileBreakpoint}px)`)

  const handleOnClick = (event: React.MouseEvent) => {
    onClick?.(event)
  }

  const handleOnClickProduct = (event: React.MouseEvent) => {
    product?.onClick?.(event)
  }

  return (
    <Container>
      {isSmallScreen ? (
        <>
          <A
            href={href}
            onClick={handleOnClick}
            role={!href ? 'button' : undefined}
          >
            <BrandSportsyou
              fill={color ?? Colors.PUNCH}
              height={29}
              width={168}
            />
          </A>
          {product ? (
            product.onClick ? (
              <ProductLink
                aria-label={product.title}
                color={color}
                onClick={handleOnClickProduct}
                title={product.title}
              >
                {product.name}
              </ProductLink>
            ) : (
              <ProductText
                color={color}
                id='product-name'
                onClick={handleOnClickProduct}
              >
                {product.name}
              </ProductText>
            )
          ) : null}
        </>
      ) : (
        <A
          href={href}
          onClick={handleOnClick}
          role={!href ? 'button' : undefined}
        >
          <BrandSy fill={color ?? Colors.PUNCH} height={36} width={36} />
        </A>
      )}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: inline-flex;
  margin: 0;
`

const A = styled.a`
  cursor: pointer;
  text-decoration: none;
`
const Product = css`
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
`
const ProductLink = styled.a<{ color?: string }>`
  ${Product};
  color: ${({ color }) => color ?? Colors.MINE_SHAFT};
  cursor: pointer;
  text-decoration: none;
  // line-height: 25px;
  // margin-top: -3px;
  // padding-left: 10px;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }

  @media print {
    display: none;
  }
`
const ProductText = styled.span<{ color?: string }>`
  ${Product};
  color: ${({ color }) => color ?? Colors.MINE_SHAFT};
  // line-height: 25px;
  // margin-top: -3px;
  // padding-left: 10px;
`

// const SyProductLogoText = styled.div`
//   color: #2b2b2b;
//   font-size: 25px;
//   font-weight: bold;
//   line-height: 25px;
//   margin-top: -3px;
//   padding-left: 10px;
// `

export default NavbarBrand
