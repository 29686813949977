import moment from 'moment'

import { Campaign, Image as ImageProps, Upload } from '@sportsyou/api'
import { Colors, getTranscodeUrl } from '@sportsyou/core'

export function campaignStatusString(campaign: Campaign) {
  if (campaign.status === 'active') {
    if (moment().isAfter(moment(campaign.endDate))) {
      return 'Ended'
    }
    if (moment().isBefore(moment(campaign.startDate))) {
      return 'Approved'
    }
    return 'Active'
  }
  if (campaign.status === 'deactivated') {
    return 'Ended'
  }
  if (campaign.status === 'pending') {
    return 'Pending approval'
  }
  if (campaign.status === 'completed') {
    return 'Completed'
  }
  return 'Unknown'
}

export function isCampaignActive(campaign: Campaign) {
  return campaignStatusString(campaign) === 'Active'
}

export function isCampaignPending(campaign: Campaign) {
  return campaignStatusString(campaign) === 'Pending approval'
}

export function isCampaignEnded(campaign: Campaign) {
  return campaignStatusString(campaign) === 'Ended'
}

export function campaignStatusIndicatorData(campaign: Campaign) {
  const label = campaignStatusString(campaign)
  let color = Colors.BLACK
  switch (label) {
    case 'Active':
      color = Colors.MOUNTAIN_MEADOW
      break
    case 'Pending approval':
      color = Colors.GOLD
      break
    case 'Approved':
      color = Colors.MOUNTAIN_MEADOW
      break
    case 'Completed':
      color = Colors.HAVELOCK_BLUE
      break
    case 'Ended':
      color = Colors.PUNCH
      break
  }
  return { color, label }
}

export function isCampaignAlwaysShown(campaign: Campaign) {
  return campaign.weight === 121
}

export function campaignAssetTranscodeUrl(
  asset: Upload,
  loadingScreen = false
) {
  return getTranscodeUrl({
    upload: (asset?.transcodes as ImageProps[]) ?? [],
    transcodeTypes: loadingScreen
      ? ['campaignAssetLoadingScreen', 'media']
      : ['campaignAsset', 'media'],
  })
}
